<template>
  <div>
    <b-modal id="SignupConfirmModal" no-close-on-backdrop hide-footer hide-header size="lg" class="p-0 m-0">
     
        <div  class="signUpConfirm p-0 m-0" >
         <div class="row h-100 ">
      <div class="col-12   MainSection">
        <div class="">
          <!-- Logo -->
          <div class="text-center">
            <img
              src="assets\img\new-website\LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell"
          title="product recommendation"
              class="img-fluid"
              @click="$router.push('/')"
            />
          </div>

          <div class="TitleDiv mt-3">
            <h1 class="title">Welcome to Quizell</h1>
          </div>

          <div class="buttonDiv mt-3 d-flex flex-column align-items-center">

                <div class="mb-3">
                    <button class="btn" @click="openShopify">  <img src="https://images.quizell.com/website%2Fshopify.svg" 
          title="shopify quiz app" alt="Shopify Quizell logo" class="mr-2">  Sign Up with Shopify</button>
                </div>
                <div class="mb-3">
                    <button class="btn" @click="openWix">  <img src="https://cdn.shopify.com/s/files/1/0620/9998/0532/files/5968753.png?v=1664789896" 
          title="shopify quiz app" alt="Wix Quizell logo" class="mr-2">  Sign Up with Wix App</button>
                </div>
                <div class="mb-3">
                    <button class="btn" @click="openBC">  <img src="https://images.quizell.com/website%2FGroup%2069213.svg" 
          title="shopify quiz app" alt="Wix Quizell logo" class="mr-2">  Sign Up with BigCommerce</button>
                </div>
                <div class="mb-3">
                    <button class="btn" @click="closeModal">  <img src="https://images.quizell.com/website%2Fwoocommerce.svg" 
          title="shopify quiz app" alt="Wix Quizell logo" class="mr-2">  Sign Up with Woocommerce</button>
                </div>
                <div class="mb-3">
                    <button class="btn" @click="closeModal"><b-icon-envelope-fill style="height:25px;width:20px;color:#ffffff;" class="mr-3"/> Sign Up with email</button>
                </div>

          </div>

           <div class="mt-3">
            <p class="text-center register" >
              Already have an account? <button class="btn m-0 p-0" style="font-weight:600;color:#4d1b7e;" @click="$router.push('/login')"> Login</button>
            </p>
            
          </div>

         
        </div>
      </div>
    </div>
        </div>
     
    </b-modal>
   
  </div>
</template>

<script>
import { BIconEnvelopeFill} from "bootstrap-vue";
export default {
    components:{
        BIconEnvelopeFill
    },
  data() {
    return{

    }
  },
  
  methods: {
    OpenModal() {
         this.$bvModal.show('SignupConfirmModal')
        
    },
    closeModal(){
         this.$bvModal.hide('SignupConfirmModal')
    },
   openShopify(){
    
             window.open('https://apps.shopify.com/quizell-product-recommendation','_self')
        
   },
   openWix(){
    
             window.open('https://www.wix.com/app-market/quizell-product-quiz','_self')
        
   },
   openBC(){
    
             window.open('https://www.bigcommerce.com/apps/quizell-product-quiz/','_self')
    
   },
  
  },
  mounted() {
   
  },
};
</script>

<style scoped>


.SignupConfirmModalContent {
  border-radius: 20px;
}
.signUpConfirm {
     height: 40vh;
}

.signUpConfirm .title{
  
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 60px;
text-align:center;
color: #161E34;

}
.signUpConfirm .register{
font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #161e34;

}

.signUpConfirm .MainSection {
  padding: 2% 5%;
  background: #ffffff;
}

.objectSection {
  background-image: url("/assets/new-website/loginObject.png");
  background-color: #ffffff;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.buttonDiv button{
    /* text-align: center;
    background:red;
    width:300px; */
    width:300px;
        align-items: center;
    border-radius: 4px;
    background:#4d1b7e;
    color:#ffffff;
    font-weight:500;
    display: flex;
    flex-wrap: nowrap;
    height: 40px;
    justify-content: center;
    line-height: 25px;
    padding: 7px 15px;
    white-space: nowrap;
}
.buttonDiv button img{
   height:25px;
   width:25px;
}

</style>
